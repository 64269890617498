import React, { Suspense } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import './App.css';
import Loader from './pages/Loader';
import SearchForm from './pages/SearchForm';
import SearchView from './pages/SearchView';

const VerdictComponent = React.lazy(() => import('./pages/Verdict'));

function RouterComponent() {
  return (
    <Router>
      <SearchForm />

      <div>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/debug">
              <ul>
                <li>
                  <Link to="/c/91368KGZA08-27">voorbeeld</Link>
                </li>
              </ul>
            </Route>

            <Route path="/c/:id">
              <VerdictComponent />
            </Route>
            <Route path="/">
              <SearchView />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default RouterComponent;
