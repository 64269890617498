import React, { createContext, useReducer } from 'react';

export interface SearchState {
  search: string;
}

const reducer = (
  state: SearchState,
  newState: Partial<SearchState>
): SearchState => {
  return { ...state, ...newState };
};
const SearchContext = createContext<{
  state: SearchState;
  dispatch: React.Dispatch<Partial<SearchState>>;
}>({} as any);

const { Provider } = SearchContext;

const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, { search: '' } as SearchState);

  const value = { state, dispatch };

  return <Provider value={value}> {children} </Provider>;
};

export const SearchProvider = StateProvider;

export default SearchContext;
