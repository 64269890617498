import { VerdictModel } from "../model/verdict";

const { SearchClient, AzureKeyCredential } = require("@azure/search-documents")

export const Client = new SearchClient(
  `https://arr.search.windows.net/`,
  "verdicts",
  new AzureKeyCredential("9F22747EDE3D1E556D23751590F3FA2D")
)

export async function SearchVerdictById(id: string) {
  return Client.search("*", {
    filter: `CaseNumber eq '${id}'`
  }).then((x: any) => mapping(x));
}

export async function SearchVerdict(input: string) {
  return Client.search(input, {
  }).then((x: any) => mapping(x));
}

async function mapping(searchResults: any) {
  const list: VerdictModel[] = []
  for await (const result of searchResults.results) {
    list.push({
      ...result.document,

    })

  }
  return list
}

