import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SearchContext from '../context/searchContext';

export default function Verdict() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state, dispatch } = useContext(SearchContext);
  const history = useHistory();

  function handleClick() {
    history?.push('/home');
    onClose();
  }
  return (
    <>
      <div>
        <Input onClick={onOpen} />
      </div>
      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Doorzoek de database</DrawerHeader>

          <DrawerBody>
            <Input
              placeholder="Zoek naar uitspraken"
              value={state.search}
              onChange={(x) => dispatch({ search: x.target.value })}
              autoFocus
            />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              sluit
            </Button>
            <Button colorScheme="blue" onClick={handleClick}>
              Zoek
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
