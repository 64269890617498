import { Heading } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchContext from '../context/searchContext';
import { VerdictModel } from '../model/verdict';
import { SearchVerdict } from '../utility/search';

export default function SearchView() {
  const { state } = useContext(SearchContext);
  const [items, setItems] = useState<VerdictModel[]>([]);

  useEffect(() => {
    if (state.search) {
      SearchVerdict(state.search).then((items) => setItems(items));
    }
  }, [state.search]);

  if (state.search) {
    <></>;
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <Heading style={{ marginBottom: '2rem' }}>{state.search}</Heading>
      <div>
        {items.map((item) => (
          <Link to={'/c/' + item.CaseNumber} key={item.CaseNumber}>
            <Heading as="h3" size="small">
              {item.CaseNumber}
            </Heading>
            <div> {item.ContentIndication}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}
