import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import './App.css';
import { SearchProvider } from './context/searchContext';
import RouterComponent from './Router';

function App() {
  return (
    <ChakraProvider>
      <SearchProvider>
        <div id="container">
          <div
            style={{
              fontSize: '80%',
              textAlign: 'center',
              padding: '1rem 0',
              color: '#999',
              letterSpacing: '0.1rem',
            }}
          >
            Arbeidsmarktresearch B.V. Search
          </div>
          <div style={{ minHeight: '80vh' }}>
            <RouterComponent />
          </div>
          <div style={{ textAlign: 'center' }}>© Joost van Velthoven</div>
        </div>
      </SearchProvider>
    </ChakraProvider>
  );
}

export default App;
